import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

export default responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      main: '#fdd835',
      contrastText: "#3f3f3f",
    },
    secondary: {
      main: '#3a536b',
      contrastText: '#fff'
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
}));

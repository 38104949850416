import React from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import Slide from "@material-ui/core/Slide";
import {Link} from "gatsby-theme-material-ui"
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";
import HandIcon from "./HandIcon";
import Hidden from "@material-ui/core/Hidden";
import navigation from "../navigation";

const NavigationBar = ({onMenu}) => {
  const trigger = useScrollTrigger({target: (typeof window !== `undefined` ? window : undefined)})

  return (
    <React.Fragment>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar color={"primary"}>
          <Toolbar disableGutters={true}>
            <Button component={Link} to="/" color="inherit">
              <HandIcon/>
              <Box mx={1}>
                <Typography variant="body1">
                  Gü.guru
                </Typography>
              </Box>
            </Button>
            <Hidden smDown implementation="css">
              {navigation.navigationPrimary.map(x =>
                <Button key={x.title} component={Link} to={x.path} color="inherit" underline={"none"}>
                  <Box height="70px" alignItems="center" justifyContent="center" display="flex">
                    <Typography variant={"h6"}>
                      {x.title}
                    </Typography>
                  </Box>
                </Button>
              )}
              {navigation.navigationSecondary.map(x =>
                <Button key={x.title} component={Link} to={x.path} color="inherit" underline={"none"}>
                  <Box height="70px" alignItems="center" justifyContent="center" display="flex">
                    <Typography variant={"body1"}>
                      {x.title}
                    </Typography>
                  </Box>
                </Button>
              )}
            </Hidden>
            <Box flexGrow={1}/>
            <Hidden mdUp implementation="css">
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onMenu}
              >
                <MenuIcon/>
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
      </Slide>
      <Box height={82}/>
    </React.Fragment>
  );
}

NavigationBar.propTypes = {
  onMenu: PropTypes.func
}

export default NavigationBar;

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gesellschaft-js": () => import("./../../../src/pages/gesellschaft.js" /* webpackChunkName: "component---src-pages-gesellschaft-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-korbflechten-js": () => import("./../../../src/pages/korbflechten.js" /* webpackChunkName: "component---src-pages-korbflechten-js" */),
  "component---src-pages-shiatsu-js": () => import("./../../../src/pages/shiatsu.js" /* webpackChunkName: "component---src-pages-shiatsu-js" */),
  "component---src-pages-teckno-yoga-js": () => import("./../../../src/pages/teckno_yoga.js" /* webpackChunkName: "component---src-pages-teckno-yoga-js" */),
  "component---src-pages-yoga-js": () => import("./../../../src/pages/yoga.js" /* webpackChunkName: "component---src-pages-yoga-js" */),
  "component---src-templates-gesellschaft-blog-js": () => import("./../../../src/templates/gesellschaft_blog.js" /* webpackChunkName: "component---src-templates-gesellschaft-blog-js" */)
}


import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import GatsbyImage from "gatsby-image";

const query = graphql`
    query {
        file(relativePath: { eq: "images/favicon.png" }) {
            childImageSharp {
                fixed(height: 70, width: 60) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`

const HandIcon = () => {
  const data = useStaticQuery(query)

  return (
    <React.Fragment>
      <GatsbyImage fixed={data.file.childImageSharp.fixed}/>
    </React.Fragment>
  )
}

export default HandIcon

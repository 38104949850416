export default {
  "navigationPrimary": [
    {
      "title": "Shiatsu",
      "path": "/shiatsu"
    },
    {
      "title": "TecknoYoga",
      "path": "/teckno_yoga"
    },
    {
      "title": "Yoga",
      "path": "/yoga"
    },
    {
      "title": "Korbflechten",
      "path": "/korbflechten"
    },
    {
      "title": "Gesellschaft",
      "path": "/gesellschaft"
    }
  ],
  "navigationSecondary": [
    {
      "title": "Kontakt",
      "path": "/contact"
    },
    {
      "title": "Impressum",
      "path": "/impressum"
    }
  ]
}

import React, {useState} from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby-theme-material-ui"

import theme from '../themes/default';
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from '@material-ui/core/styles';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Header from "./Header"
import navigation from '../navigation'
import {useMediaQuery} from "@material-ui/core";

const Layout = ({children}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <SwipeableDrawer anchor="right" open={menuOpen} onClose={() => setMenuOpen(false)} onOpen={() => setMenuOpen(true)}>
        <Box bgcolor="secondary.main" height={1} color="secondary.contrastText" width={0.3} minWidth={200}>
          <Box>
            <Button component={Link} onClick={() => setMenuOpen(false)}
                    to="/"
                    color="inherit">
              <Typography variant={"h6"}>
                Home
              </Typography>
            </Button>
          </Box>
          {navigation.navigationPrimary.map(x =>
            <Box key={x.title}>
              <Button component={Link} onClick={() => setMenuOpen(false)}
                      to={x.path}
                      color="inherit">
                <Typography variant={"h6"}>
                  {x.title}
                </Typography>
              </Button>
            </Box>
          )}
          <Box mx={1}>
            <Divider color="primary" variant="fullWidth"/>
          </Box>
          {navigation.navigationSecondary.map(x =>
            <Box key={x.title}>
              <Button component={Link} onClick={() => setMenuOpen(false)}
                      to={x.path}
                      color="inherit">
                <Typography variant={"body1"}>
                  {x.title}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </SwipeableDrawer>
      <Header onMenu={() => setMenuOpen(true)}/>
      <Box align={smUp ? "inherit" : "center"}>
      {children}
      </Box>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
